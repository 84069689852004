
<template>
    <div
        id="component"
    >
       <div class="filter-panel">
           <CSSelect style="margin-right: 20px; width: 210px;">
               <el-date-picker
                   v-model="queryParams.startDate"
                   type="datetime"
                   format="yyyy-MM-dd HH:mm"
                   value-format="yyyy-MM-dd HH:mm"
                   placeholder="开始时间"
                   prefix-icon="el-icon-time"
                   :editable="false"
               >
               </el-date-picker>
           </CSSelect>
           <span style="margin-left: -10px;margin-right: 10px;">-</span>
           <CSSelect style="margin-right: 20px; width: 210px;">
               <el-date-picker
                   v-model="queryParams.endDate"
                   type="datetime"
                   format="yyyy-MM-dd HH:mm"
                   value-format="yyyy-MM-dd HH:mm"
                   placeholder="结束时间"
                   prefix-icon="el-icon-time"
                   :editable="false"
               >
               </el-date-picker>
           </CSSelect>

           <template
               v-if="
                                    [1001, 1002].includes(
                                        Number(staffInfo.dutyType)
                                    )
                                "
           >
               <CSSelect style="margin-right: 20px; padding-left: 5px;">
                   <select
                       style="width: 150px;"
                       v-model="queryParams.orgId"
                   >
                       <option value="">全部部门</option>
                       <option
                           v-for="(org, index) in orgList"
                           :value="org.code"
                           :key="index"
                       >
                           {{ org.name }}
                       </option>
                   </select>
               </CSSelect>
           </template>
           <button
               class="btn btn-search btn-primary"
               style="height: 30px; margin-left: 10px; padding: 0; width: 50px;"
               @click="refreshLog"
           >
               查询
           </button>
       </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button class="btn btn-primary sticky-right" @click="downloadHandoverLog">下载交接记录</button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th class="text-center">提交时间</th>
                        <th class="text-center">部门</th>
                        <th class="text-center">交接人</th>
                        <th class="text-center">接班人</th>
                        <th class="text-center">内容</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="memoList.length > 0">
                        <tr v-for="(item, index) in memoList" :key="item.date + '-' + index" >
                            <td class="text-center">
                                {{ item.date }}
                            </td>
                            <td class="text-center">
                                {{ item.orgName }}
                            </td>

                            <td class="text-center">
                                {{ item.fromUser }}
                            </td>
                            <td class="text-center">
                                {{ item.toUser }}
                            </td>
                            <td class="text-center">
                                <div
                                    class="btn btn-link"
                                    @click="
                                                        lookHandoverReason(item.memo)
                                                    "
                                >
                                    查看
                                </div>
                            </td>
                            </tr>
                    </template>
                </template>
            </CSTable>
            <Pagination />
        </div>

        <ViewModal name="viewModal" componentName="ViewModal"></ViewModal>
    </div>
</template>
<script>
import ViewModal from "@/components/ViewModal.vue";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import { ORG_LEVEL } from "@/constant";
import {
    downloadAttendanceDataExcelUrl,
    downloadHandoverLogUrl,
    queryDepartmentUrl,
    queryHandoverLogUrl
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
    props: {},
    created(){
        window.addEventListener("keydown",this.refreshLogDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.refreshLogDown);
    },
    deactivated(){
         window.removeEventListener("keydown",this.refreshLogDown);
    },
    components: {
        CSTable,
        ViewModal,
        CSSelect,
        Pagination,
    },
    data() {
        return {
            staffInfo: this.$vc.getCurrentStaffInfo(),
            orgList: [],
            filterHeight: 0,
            queryParams: {
                startDate: dayjs().subtract(1, 'month').format("YYYY-MM-DD") + ' 00:00',
                endDate: dayjs().format("YYYY-MM-DD") + ' 23:59',
                orgId: ""
            },
            memoList: [],
        };
    },

    async mounted() {
        await this.getOrgList();
        this.queryLog();
        this.$vc.on(this.$route.path, 'pagination_page', 'event', this.queryLog);
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods: {
        refreshLogDown(e){
            if(e.keyCode == 13){
                this.refreshLog();
            }
        },
        getOrgList() {
            return this.$fly
                .post(queryDepartmentUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    code: "",
                    name: "",
                    dutyType: "",
                    parentCode: "",
                    level: ORG_LEVEL.DEPARTMENT,
                    pageNo: 1,
                    pageSize: 50,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.orgList = res.data;
                    return res.data;
                });
        },
        refreshLog() {
            this.queryLog();
        },
        lookHandoverReason(content) {
            let contentStr = "";
            content.split("\n").forEach((text, index) => {
                contentStr +=
                    (index + 1) % 2 == 0
                        ? `<p style="text-indent: 1em;">${text}</p>`
                        : `<p>${text}</p>`;
            });
            this.$CSDialog.alert({
                title: '查看内容',
                messageHtml: `<div style="padding: 12px; text-align:left;font-size: 20px; border-radius: 8px;width: 100%;height: 300px;overflow-y: auto;">
                    ${contentStr}
                </div>`
            })
        },
        downloadHandoverLog() {
            this.$fly.post(downloadHandoverLogUrl, {
                ...this.queryParams,
                operatorId: this.staffInfo.id,
            }, {
                responseType: 'arraybuffer',
                headers: {
                    notParse: true,
                }
            })
                .then(res => {
                    const blob = new Blob([res], { type: 'application/vnd.ms-excel'});
                    if ("download" in document.createElement("a")) {
                        const elink = document.createElement("a");
                        // elink.download = fileName;
                        elink.style.display = "none";
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href);
                        document.body.removeChild(elink);
                    } else {
                        navigator.msSaveBlob(blob);
                    }
                })
        },
        queryLog(pageNo = 1, pageSize = 10) {
            let params = {
                startDate: "",
                endDate: "",
                orgId: "",
                operatorId: this.staffInfo.id,
                pageNo,
                pageSize
            };
            this.$vc.copyObject(this.queryParams, params);
            if (params.orgId == null || params.orgId === "") {
                delete params.orgId;
            }
            this.$fly.post(queryHandoverLogUrl, params).then((res) => {
                let memoList = [],
                    data = res.data.datas;
                data.forEach((date) => {
                    date.memoList.forEach((val) => {
                        memoList.push({
                            ...val,
                            date: `${date.date} ${val.time}`,
                        });
                    });
                });
                if (pageNo === 1) {
                    this.$vc.emit(this.$route.path, 'pagination', 'init', {
                        total: res.data.total,
                        pageSize,
                        currentPage: pageNo
                    })
                }
                this.memoList = memoList.slice();
            });
        },
    },
};
</script>
<style>
</style>
